<template>
  <VLayout
    :class="[
      'menu-entity--buttons-wrap',
      bottom ? 'bottom' : 'top',
      `${entityName}-buttons`,
    ]"
  >
    <VFlex class="menu-entity--buttons shrink">
      <EntityAddButton
        v-show="!hideButtons"
        :class="['flex', { 'add-hover': addHover }]"
        :color="addBtnColor"
        :md-icon-position="addHover ? 'left' : 'right'"
        :icon="!addHover"
        @click="$emit('add')"
        @mouseover="addHover = true"
        @mouseleave="addHover = false"
        >{{ addHover ? entityName : '' }}</EntityAddButton
      >
      <EntityDeleteButton
        v-if="showDelete && !hideButtons"
        class="flex"
        @delete="$emit('deleteBtnClick')"
        @mouseover="deleteHover = true"
        @mouseleave="deleteHover = false"
      />
    </VFlex>
    <div class="border-line" :style="borderLineStyles"></div>
  </VLayout>
</template>

<script>
import EntityAddButton from '@menu/EntityAddButton'
import EntityDeleteButton from '@menu/EntityDeleteButton'

export default {
  name: 'EntityModButtons',
  components: {
    EntityAddButton,
    EntityDeleteButton,
  },
  props: {
    showDelete: { type: Boolean, default: false },
    hideButtons: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    addBtnColor: { type: String, default: 'green' },
    deleteBtnColor: { type: String, default: 'red' },
    entityName: { type: String, default: 'Item' },
  },
  data() {
    return {
      addHover: false,
      deleteHover: false,
    }
  },
  computed: {
    borderLineStyles() {
      let bgColor = this.addHover
        ? '#37c120'
        : this.deleteHover
        ? this.deleteBtnColor
        : this.hideButtons
        ? 'transparent'
        : 'transparent'
      return {
        'background-color': bgColor,
      }
    }, // borderLineStyles
  },
  watch: {
    deleteHover: function(newVal, oldVal) {
      this.$emit('deleteHover', newVal)
    },
  }, // watch
}
</script>

<style lang="scss">
$hover-border-w: 2px;

.menu-entity--buttons-wrap {
  $overhang: 7px;

  position: absolute;
  // left: -$overhang;
  left: 0;
  justify-content: center;
  // width: calc(100% + #{$overhang * 2});
  width: 100%;
  height: 25px;

  .menu-entity--buttons {
    z-index: 929;
  }

  .border-line {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 2px;
    transition: background-color 0.1s ease;
  }

  &.top {
    top: 0;
    // top: ($hover-border-w * 4) - $hover-border-w;
    .menu-entity--buttons {
      transform: translateY(-50%);
    }
    .border-line {
      bottom: 100%;
    }
  }
  &.bottom {
    top: auto;
    bottom: 0;
    // bottom: ($hover-border-w * 4) - $hover-border-w;
    .menu-entity--buttons {
      transform: translateY(50%);
    }
    .border-line {
      top: 100%;
    }
  }

  .v-btn {
    &.add-hover {
      width: auto;
    }
  }
}

.entity-delete-btn {
  margin-left: 5px;
}
</style>
