<template>
  <VFlex
    :class="[
      'menu-item--prices font-weight-bold text-xs-right',
      { 'is-dirty': isDirty },
    ]"
    :shrink="!!prices.length"
    :grow="!prices.length"
  >
    <VFlex
      v-if="!prices.length && isShowingEditUI"
      class="price-placeholder"
      @click.stop="addPrice"
      >{{ placeholder }}</VFlex
    >
    <template v-for="(price, i) of prices" v-else>
      <!-- TODO (ESS) : How to compare a Number and a String
      Right now I'm forcing the price to be a passed as a string
      so the "dirty" calculation in the Editable component can be
      compared effectively (otherwise it compares a string to a number)-->
      <MenuItemPrice
        :key="`price-${i}`"
        :ref="`price-${i}`"
        :price="price === null ? '' : `${price}`"
        :class="[`item-price-${i}`]"
        :placeholder="placeholder"
        @update="updatePrices(i, $event)"
        @keydown.native="onKeydownGeneric(i, $event)"
        @keydown.enter.native="onKeyupEnter(i, $event)"
        @keydown.delete.native="onKeydownDelete(i, $event)"
        @onBlur="onBlur(i, $event)"
        @dirtyUpdate="adjustDirtScore"
      />
      <span
        v-if="prices.length > 1 && i < prices.length - 1"
        :key="`divider-${i}`"
        class="divider"
        >{{ divider }}</span
      >
    </template>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'
import PricesArray from '@mixins/prices-array'
import GetsDirty from '@mixins/gets-dirty'
import MenuItemPrice from '@menu/MenuItemPrice'
export default {
  name: 'MenuItemPrices',
  components: { MenuItemPrice },
  mixins: [GetsDirty('ITEM_PRICES'), PricesArray],
  data() {
    return {}
  },
  computed: mapGetters('editor', ['isShowingEditUI']),
}
</script>
<style lang="scss">
@import '@design';

.menu-item--prices {
  flex: auto;
  justify-content: flex-end;
  text-align: right;
}

.item-price {
  flex: 0 1 auto;
  &.r-edit.empty-text {
    display: inline-block;
    min-width: 8px;
    text-align: left;
    border-bottom: 3px solid $color-db-green;
  }
}
.divider {
  flex: 0 1 auto;
}

.price-placeholder {
  font-style: italic;
  color: darken(white, 25%);
  cursor: pointer;
}
</style>
