import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'

import MenuItemTitle from '@menu/MenuItemTitle'
import MenuItemDescription from '@menu/MenuItemDescription'
import MenuItemPrices from '@menu/MenuItemPrices'
import MenuItemAddons from '@menu/MenuItemAddons'
import MenuItemAttrs from '@menu/MenuItemAttrs'
import EntityModButtons from '@menu/EntityModButtons'
import { ENTITY_LOOKUP, ENTITY_TYPES } from '@constants/lookupTables'
import { mapGetters } from 'vuex'
import EntityMixin from '@mixins/entity-mixin'
import GetsDirty from '@mixins/gets-dirty'
import selectElementText from '@utils/select-element-text'

export var MenuItemMixin = {
  components: {
    MenuItemTitle,
    MenuItemDescription,
    MenuItemPrices,
    MenuItemAddons,
    MenuItemAttrs,
    EntityModButtons,
  },
  mixins: [EntityMixin(ENTITY_TYPES.ITEMS), GetsDirty(ENTITY_LOOKUP.MenuItem)],
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    sectionType: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: () => Date.now().toString(),
    },
    allowAttrs: {
      type: Boolean,
      default: true,
    },
    allowDesc: {
      type: Boolean,
      default: true,
    },
    allowPrices: {
      type: Boolean,
      default: true,
    },
    descProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isHovering: false,
      isSelected: false,
      deleteHover: false,
    }
  },
  computed: {
    ...mapGetters('items', ['getNewAddon']),
    ...mapGetters('editor', ['isShowingEditUI']),
    ...mapGetters('auth', ['loggedIn']),

    prices() {
      let itemPrices =
        typeof this.entity.prices === 'string'
          ? JSON.parse(this.entity.prices)
          : this.entity.prices
      return this.myMods.prices || itemPrices || []
    }, // prices

    priceMods() {
      return _get(this.myMods, 'prices')
    }, // priceMods

    descMods() {
      return _get(this.myMods, 'description')
    }, // descMods

    addons() {
      return _get(this.myMods, 'addons', _get(this.entity, 'addons', []))
    }, // addons

    areAttsModified() {
      return Object.keys(this.myMods).includes('attributes')
    }, // areAttsModified

    areAddonsModified() {
      return Object.keys(this.myMods).includes('addons')
    }, // areAddonsModified

    isTrashed() {
      return !!this.entity.isTrashed
    }, // isTrashed

    showHoverControls() {
      return this.isHovering && this.isShowingEditUI
    }, // showHoverControls
  }, // computed
  watch: {
    areAttsModified(areModified) {
      this.adjustDirtScore(areModified)
    },
    areAddonsModified(areModified) {
      this.adjustDirtScore(areModified)
    },
    updatedAt(updatedAt) {
      this.original = { ...this.original, ...this.entity }
    },
  },
  methods: {
    emitItemUpdated(key, val) {
      // console.log('[ menu-item.js ] emitItemUpdated()', { key, val })
      this.$emit('itemUpdated', {
        item: { ...this.entity, ...this.myMods },
        key,
        val,
      })
    }, // emitItemUpdated

    clickawayFn() {
      this.isSelected = false
    }, // clickawayFn

    itemClickAction() {
      if (this.loggedIn) {
        this.isSelected = true
      }
    }, // itemClickAction

    newAddonAction() {
      if (!this.isShowingEditUI) return false
      let updatedAddons = [
        ..._get(this.myMods, 'addons', _get(this.entity, 'addons', [])),
      ]
      updatedAddons.push(this.getNewAddon(this.id))
      this.emitItemUpdated('addons', updatedAddons)
    }, // newAddonAction

    removeAddonAction(removeThisAddon) {
      if (!this.isShowingEditUI) return false
      let updatedAddons = [
        ..._get(this.myMods, 'addons', _get(this.entity, 'addons', [])),
      ]
      updatedAddons.splice(_findIndex(updatedAddons, removeThisAddon), 1)
      this.emitItemUpdated('addons', updatedAddons)
    }, // removeAddonAction

    updateAddonAction({ addon, key, val }) {
      if (!this.isShowingEditUI) return false
      let updatedAddons = [
        ..._get(this.myMods, 'addons', _get(this.entity, 'addons', [])),
      ]
      let addonIndex = _findIndex(updatedAddons, { id: addon.id })
      let updatedAddon = (updatedAddons[addonIndex] = {
        ...updatedAddons[addonIndex],
        ...{ [key]: val },
      })
      updatedAddons.splice(addonIndex, 1, updatedAddon)
      this.emitItemUpdated('addons', updatedAddons)
    }, // updateAddonAction

    selectAttributeAction(attr) {
      if (!this.isShowingEditUI) return false
      let updatedAttrs = [
        ..._get(this.myMods, 'attributes', _get(this.entity, 'attributes', [])),
      ]
      updatedAttrs.push(attr.id)
      this.emitItemUpdated('attributes', updatedAttrs)
    }, // selectAttributeAction

    deselectAttributeAction(removeThisAttr) {
      if (!this.isShowingEditUI) return false
      let updatedAttrs = [
        ..._get(this.myMods, 'attributes', _get(this.entity, 'attributes', [])),
      ]
      updatedAttrs.splice(updatedAttrs.indexOf(removeThisAttr.id), 1)
      this.emitItemUpdated('attributes', updatedAttrs)
    }, // deselectAttributeAction

    async addDescription() {
      await this.emitItemUpdated('description', 'Description')
      if (this.$refs['item-desc'] && this.$refs['item-desc'].$el) {
        this.$refs['item-desc'].$el.focus()
        selectElementText(this.$refs['item-desc'].$el)
      }
      this.adjustDirtScore(true)
    }, // addDescription
  }, // methods
}

export default MenuItemMixin
