<template>
  <BaseButtonIconMini
    class="entity-delete-btn"
    md-icon="delete"
    :color="isHovering ? 'red' : 'grey'"
    v-on="$listeners"
    @click="$emit('delete')"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    ><slot
  /></BaseButtonIconMini>
</template>

<script>
export default {
  name: 'EntityDeleteButton',
  data() {
    return {
      isHovering: false,
    }
  },
}
</script>
