<template>
  <VFlex
    v-if="attrIds"
    :class="['menu-item--attrs', { 'is-dirty': isDirty }]"
    xs12
    mt-1
    row
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <transition-group tag="div" class="layout row wrap" name="attr-list">
      <VFlex
        v-for="attr of attributes"
        :key="attr.id"
        shrink
        mr-2
        @click.stop="$emit('deselectAttribute', attr)"
      >
        <MenuItemAttr :key="attr.id" :attr="attr" />
      </VFlex>
      <VFlex
        v-for="attr of unselectedAttrs"
        :key="attr.id"
        shrink
        mr-2
        @click.stop="$emit('selectAttribute', attr)"
      >
        <MenuItemAttr :key="attr.id" :attr="attr" unselected />
      </VFlex>
    </transition-group>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'
import _omit from 'lodash/omit'

import MenuItemAttr from '@menu/MenuItemAttr'

export default {
  name: 'MenuItemAttrs',
  components: { MenuItemAttr },
  props: {
    attrIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    isItemSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovering: false,
    }
  },
  computed: {
    ...mapGetters('entities', [
      'getEntityMods',
      'getEntitySet',
      'getAllOfEntity',
    ]),
    ...mapGetters('editor', ['isShowingEditUI']),
    ...mapGetters('attributes', ['getAttrs']),

    attributes() {
      return this.getAttrs(this.attrIds)
    }, // attributes

    unselectedAttrs() {
      let allAttrs = this.getAllOfEntity('attributes')
      let areUnselectAttrsVisible =
        (this.isHovering || this.isItemSelected) && this.isShowingEditUI
      return areUnselectAttrsVisible ? _omit(allAttrs, this.attrIds) : []
    }, // unselectedAttrs

    visibleAttrs() {
      return this.isHovering ? this.getAllOfEntity('attributes') : this.attrIds
    }, // visibleAttrs
  }, // computed
}
</script>

<style lang="scss">
@import '@design';

.is-dirty {
  min-height: 10px;
}
.attr-list-enter-active,
.attr-list-leave-active {
  transition: opacity 0.2s;
}
.attr-list-enter,
.attr-list-leave-active {
  opacity: 0;
}
.attr-list-enter-active {
  transition-delay: 0;
}

.attr-list-move {
  transition: transform 0.2s;
}
</style>
