<template>
  <BaseEditable
    ref="editable"
    tag="span"
    :content="price"
    :placeholder="placeholder"
    :mods="price"
    class="item-price font-weight-bold d-inline-block"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'MenuItemPrice',
  components: {},
  props: {
    price: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '$',
    },
  },
  data() {
    return {}
  },
  watch: {
    price(newPrice, oldPrice) {
      // console.log('[ 👀 MenuItemPrice.price ]', { newPrice, oldPrice })
      this.$refs['editable'].editor.setContent(newPrice)
    },
  },
}
</script>
