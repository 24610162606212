<template>
  <BaseButtonIconMini
    class="entity-add-btn"
    md-icon="add"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    @click="$emit('add')"
    ><slot
  /></BaseButtonIconMini>
</template>

<script>
export default {
  name: 'EntityAddButton',
  data() {
    return {}
  },
}
</script>
