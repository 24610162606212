import { ENTITY_LOOKUP, ENTITY_LOOKUP_SINGULAR } from '@constants/lookupTables'
import deepCompareMods from '@utils/deep-compare-mods'

const EntityMixin = (entityType = null) => ({
  data() {
    return {
      entityType,
      entityName: ENTITY_LOOKUP[entityType],
      singularEntityName: ENTITY_LOOKUP_SINGULAR[entityType],
      original: { ...this.entity },
      id: this.entity.id,
    }
  },
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({ isNew: true }),
    },
  },
  methods: {},
  computed: {
    myMods() {
      return deepCompareMods(this.original, this.entity)
    }, // myMods
  },
  watch: {
    'entity.updated_at'(timestamp) {
      // console.log(
      //   `[entity-mixin.js 👀 entity.updated_at] ${this.entity.title}`,
      //   {
      //     entity: this.entity,
      //     timestamp,
      //   }
      // )
      this.original = { ...this.original, ...this.entity }
      // if (this.resetDirtScore) {
      //   this.resetDirtScore()
      // }
    },
    // myMods(myMods) {
    //   console.log(`[ entity-mixin.js 👀 myMods] ${this.entity.title}`, {
    //     myMods,
    //   })
    // },
    // entity(entity) {
    //   console.log(`[ entity-mixin.js 👀 entity ] ${this.entity.title}`, {
    //     entity,
    //   })
    //   // this.original = { ...this.original, ...this.entity }
    // },
  },
})
export default EntityMixin
